/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";

// import Swiper JS
import Swiper from 'swiper/bundle';

// import Swiper styles
import 'swiper/css/bundle';

import { Fancybox } from "@fancyapps/ui";

import reframe from 'reframe.js';


/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
window.Alpine = Alpine;
window.Swiper = Swiper;
window.Fancybox = Fancybox;
Alpine.start();

Fancybox.bind();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

reframe('iframe');